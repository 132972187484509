<template>
    <div>
        <div v-if="roomInfo && roomInfo.name" class="d-flex justify-content-between">
            <div style="font-weight: 900; font-size: 1.5rem;">
                {{ roomInfo.name.replaceAll("_", " ") }} - {{ getDate(new Date(roomInfo.start_time)) }}
            </div>
            <div class="d-flex align-items-center" style="font-size: 1.2rem;">
                <div class="circle mr-1"></div> v živo
            </div>
        </div>

        <div class="row p-1 my-1" style="font-weight: 900; font-size: 1.3rem; background: #bdd9ef;">
               
        </div>

        <div class="d-flex justify-content-center mx-2" v-if="roomInfo && jitsiOptions && jitsiOptions.jwt">
            <vue-jitsi-meet
                ref="jitsiRef"
                domain="jitsiDomain"
                :options="jitsiOptions"
            ></vue-jitsi-meet>
        </div>
    </div>
    
</template>

<script>
    import { JitsiMeet } from '@mycure/vue-jitsi-meet'

    export default {
        components:{
            VueJitsiMeet: JitsiMeet
        },
        data() {
            return {
                token: '',
                jitsiDomain: process.env.VUE_APP_JITSI_URL,
                jitsiOptions: {
                    roomName: null,
                    jwt: null,
                    height: 500
                },
                roomInfo: null
            }
        },
        methods:{
            getDate(date) {
                return `${date.getDate() }.${  date.getMonth() + 1  }.${  date.getFullYear()}`
            }
        },
        async mounted() {

            try {
                const roomId = this.$route.params.room_id
            
                const data = await this.$http.get(`/api/management/v1/jitsi/getMeetingToken?room_id=${  roomId}`)
                this.token = data.data.token
                this.jitsiOptions.jwt = data.data.token
                this.jitsiOptions.roomName = data.data.room.name

                this.roomInfo = data.data.room
            } catch (err) {
                this.$printError('Prislo je do napake')
            }
            
        }
    }
</script>

<style lang="scss" scoped>
    .circle{
        width: 10px !important;
        height: 10px !important;
        border-radius: 50%;
        background: red;
    }
</style>